
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Fonts */
@font-face {
  font-family: 'Campton';
  font-style: normal;
  font-weight: 300;
  src: url('fonts/campton/Campton-Light.ttf');
}
@font-face {
  font-family: 'Campton';
  font-style: normal;
  font-weight: 500;
  src: url('fonts/campton/Campton-Book.ttf');
}
@font-face {
  font-family: 'Campton';
  font-style: normal;
  font-weight: 700;
  src: url('fonts/campton/Campton-Medium.ttf');
}

@font-face {
  font-family: 'Campton';
  font-style: normal;
  font-weight: 900;
  src: url('fonts/campton/Campton-Bold.otf') format('opentype');
}
@font-face {
  font-family: 'Museo Sans';
  font-style: normal;
  font-weight: 100;
  src: url('fonts/Museo_Sans/MuseoSans_100.woff2') format('woff2'),
  url('fonts/Museo_Sans/MuseoSans_100.woff') format('woff'),
  url('fonts/Museo_Sans/MuseoSans_100.otf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Museo Sans';
  font-style: normal;
  font-weight: 300;
  src: url('fonts/Museo_Sans/MuseoSans_300.woff2') format('woff2'),
  url('fonts/Museo_Sans/MuseoSans_300.woff') format('woff'),
  url('fonts/Museo_Sans/MuseoSans_300.otf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Museo Sans';
  font-style: normal;
  font-weight: 500;
  src: url('fonts/Museo_Sans/MuseoSans_500.woff2') format('woff2'),
  url('fonts/Museo_Sans/MuseoSans_500.woff') format('woff'),
  url('fonts/Museo_Sans/MuseoSans_500.otf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Museo Sans';
  font-style: normal;
  font-weight: 700;
  src: url('fonts/Museo_Sans/MuseoSans_700.woff2') format('woff2'),
  url('fonts/Museo_Sans/MuseoSans_700.woff') format('woff'),
  url('fonts/Museo_Sans/MuseoSans_700.otf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Museo Sans';
  font-style: normal;
  font-weight: 900;
  src: url('fonts/Museo_Sans/MuseoSans_900.woff2') format('woff2'),
  url('fonts/Museo_Sans/MuseoSans_900.woff') format('woff'),
  url('fonts/Museo_Sans/MuseoSans_900.otf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'DMSans';
  font-style: normal;
  font-weight: 300;
  src: url('fonts/DM_Sans/DMSans-Regular.ttf');
}
@font-face {
  font-family: 'DMSans';
  font-style: normal;
  font-weight: 500;
  src: url('fonts/DM_Sans/DMSans-Medium.ttf');
}
@font-face {
  font-family: 'DMSans';
  font-style: normal;
  font-weight: 700;
  src: url('fonts/DM_Sans/DMSans-Bold.ttf');
}
/* End of Fonts */

/* base styles */
* {
  font-family: "Museo Sans";
}

/* Firefox */
*:not(body):not(html) {
  scrollbar-width: thin;
  scrollbar-color: #89D7F8 white;
}

/* Chrome, Edge, and Safari */
*:not(body):not(html):not(.disable-scrollbar-style)::-webkit-scrollbar {
  width: 15px;
}

*:not(body):not(html):not(.disable-scrollbar-style)::-webkit-scrollbar-track {
  background: white;
  border-radius: 5px;
}

*:not(body):not(html):not(.disable-scrollbar-style)::-webkit-scrollbar-thumb {
  background-color: #89D7F8;
  border-radius: 14px;
  border-top: 4px solid #F4F7Fc;
    border-bottom: 4px solid white;
    border-left: 4px solid white;
    border-right: 4px solid white;
}

.popup-close-button {
  background-image: url('images/xmark-solid.svg');
  background-size: 100% 100%;
}

body {
  background-color: #E5F3F7;
  margin: 0;
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('images/bg_right.svg'), url('images/bg_left.svg');
  background-position: right bottom, left bottom;
  background-repeat: no-repeat, no-repeat;
  background-size: auto 115%, auto 110%;
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-primary {
  color: #262262;
}

.text-secondary {
  color: #F7941E;
}

.text-size-primary {
  font-size: 14.25px;
}

.card-shadow {
  box-shadow: inset 0px -5px 1px #C4C4C4, 0px 3px 0px #4D4D4D29;
}

.row {
  margin-left: -0.6rem;
  margin-right: -0.6rem;
}

.row:after, .progress-bar-container:after, .copy-link:after, .share-container:after, .tab-button-container:after,
.prize-item:after, .prize-detail-container:after, .customise-poster-content:after, .nav-points:after,
.upload-photo-container:after, .share-text-title:after, .copy-text-share-container:after, .share-buttons:after ,
.season-countdown-container:after, .time-container:after, .share-container-buttons:after {
  clear: both;
  display: table;
  content: " ";
}

.default-transition-effect {
  transition: all 0.2s ease-out;
}

.transition-width-height-bottom {
  transition-property: width,height,bottom;
}

.button-orange.transition {
  transition: background-color 0.3s,box-shadow 0.3s,transform 0.3s;
}

.button-orange.transition:active {
  transition: none;
  box-shadow: none;
  background-color: #27AAE1;
  margin-left: 0;
  transform: translateY(3px);
}

.button-orange-inverted.transition {
  transition: background-color 0.3s,box-shadow 0.3s,transform 0.3s;
}

.button-orange-inverted.transition:active {
  transition: none;
  box-shadow: none;
  background-color: #E57B41;
  margin-left: 0;
  transform: translateY(3px);
}

.prize-image img.dollar {
  filter: invert(59%) sepia(86%) saturate(420%) hue-rotate(173deg) brightness(89%) contrast(91%);
}

.prize-image img.dollar.redeemed {
  filter: invert(65%) sepia(30%) saturate(620%) hue-rotate(91deg) brightness(90%) contrast(86%);
}

.filter-white {
  filter: invert(100%) sepia(0%) saturate(7499%) hue-rotate(150deg) brightness(103%) contrast(101%);
}

.filter-gray {
  filter: invert(55%) sepia(10%) saturate(167%) hue-rotate(321deg) brightness(88%) contrast(88%);
}

.filter-orange {
  filter: invert(77%) sepia(28%) saturate(6353%) hue-rotate(346deg) brightness(99%) contrast(95%);
}

.filter-blue {
  filter: invert(56%) sepia(96%) saturate(1113%) hue-rotate(165deg) brightness(91%) contrast(92%);
}

@layer components {
  .button-orange.button-shadow {
    @apply shadow-[-2.2464px_2.9952px_#E57B41];
    @apply max-md:shadow-[-0.356vw_0.408vw_#E57B41];
  }
  
  .button-orange.button-shadow:hover {
    @apply md:shadow-[-2.2464px_2.9952px_#1B769D];
  }
  
  .button-orange-inverted.button-shadow {
    @apply shadow-[-2.2464px_2.9952px_#FFFFFF];
    @apply max-md:shadow-[-0.356vw_0.408vw_#FFFFFF];
  }
  
  .button-orange-inverted.button-shadow:hover {
    @apply md:shadow-[-2.2464px_2.9952px_#FDDBB1];
  }

  .font-campton {
    @apply font-['Campton'];
  }

  .font-dm-sans {
    @apply font-['DMSans'];
  }

  .center-vertical {
    @apply top-[50%] translate-x-[0%] translate-y-[-50%];
  }
  
  .center-horizontal {
    @apply left-[50%] translate-x-[-50%] translate-y-[0%];
  }
  
  .middle-div {
    @apply top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%];
  }

  .content{
    @apply max-sm:px-[10px] max-md:max-w-[620px] max-lg:max-w-[748px] max-w-[1024px] w-full mx-auto;
  }

  footer {
    @apply md:fixed z-50 bg-[#262262] w-full bottom-0;
  }

  footer .footer-container {
    @apply h-[47.25px] content py-[15.75px];
    @apply max-lg:max-w-none max-lg:px-[32px];
    @apply max-md:h-[118px] max-md:pt-[38px] max-md:pb-[35.43px];
  }

  footer .copyright {
    @apply float-left text-white font-campton text-[15px];
    @apply max-md:float-none max-md:text-center max-md:text-[17px];
    @apply max-2xs:text-[15px];
  }

  footer .tnc-pp-container {
    @apply float-right text-white font-campton text-[15px];
    @apply max-md:float-none max-md:text-center max-md:text-[17px];
    @apply max-2xs:text-[15px];
  }

  .header-mobile {
    @apply max-md:block hidden h-[63px] pl-[23.25px] pr-[30px] shadow-[0px_3px_8px_#00000014] relative w-full;
  }

  .header-desktop {
    @apply max-md:hidden flex items-center justify-between content h-[88.5px];
    @apply max-lg:max-w-none max-lg:px-[32px] max-lg:h-[63px];
  }

  .faq-link {
    @apply h-[38px] w-[38px] absolute center-vertical left-[31px];
  }

  .row.homepage-content {
    @apply pt-[113px] pb-[98.25px];
    @apply max-md:pt-[63px] max-md:pb-[15.73px];
    @apply max-lg:mx-0 max-lg:pt-[63px];
  }

  .homepage-content > .col {
    @apply max-lg:w-full max-lg:px-[32px];
    @apply max-md:px-0;
  }

  .homepage-content > .col:first-child {
    @apply max-lg:pt-[32px] max-lg:pb-[24.25px];
    @apply max-md:pt-0;
  }

  .logo {
    @apply w-[363px] float-left max-w-[500px];
    @apply max-lg:w-[309.67px];
  }

  .header-mobile .faq, .header-mobile .profile-picture {
    @apply w-[38px] h-[38px] absolute center-vertical;
  }

  .header-mobile .faq label {
    @apply text-[30px] leading-[43px];
  }

  .header-mobile .login-button {
    @apply absolute float-right center-vertical right-[30px] w-[76px] h-[32px] px-[16px] py-[9px] text-[16px] leading-[16px];
    @apply max-2xs:text-[12px] max-2xs:w-auto max-2xs:px-[12px];
  }

  .header-mobile a:not(.faq-link) {
    @apply absolute middle-div;
  }

  .header-mobile .logo {
    @apply w-[146px] h-[42.54px] mx-auto float-none;
    @apply max-2xs:h-auto max-2xs:w-[85px];
  }

  .header-mobile .text-logo {
    @apply text-[18px] m-0;
    @apply max-2xs:text-[14px];
  }

  .header-mobile .profile-picture {
    @apply right-[30px];
  }

  .popup-overlay.profile-popup-overlay {
    z-index: 49 !important;
  }
  
  .popup-overlay .profile-popup-content {
    margin-top: 78px !important;
    @apply w-[90%];
  }
  
  .faq {
    @apply bg-[#27AAE1] cursor-pointer float-left font-bold h-[41.25px] text-center text-white rounded-full w-[41.25px] default-transition-effect;
    @apply max-lg:w-[38px] max-lg:h-[38px];
    @apply md:hover:bg-[#F7941E] md:hover:scale-[1.1];
  }

  .faq label {
    @apply cursor-pointer leading-[45.25px] text-[22px] font-extrabold font-campton;
    @apply 2xl:text-[23px] 2xl:leading-[48px];
    @apply max-lg:text-[22px] max-lg:leading-[42px];
  }

  .login-button {
    @apply px-[1.12rem] button button-blue font-campton text-[18.75px] ml-[22.5px] font-extrabold;
  }

  .profile-picture {
    @apply float-left ml-[20.628px] bg-gray-500 w-[41.25px] h-[41.25px] text-white rounded-full text-center font-bold overflow-hidden relative max-w-[42px] max-h-[42px];
    @apply 2xl:ml-[30px];
    @apply max-lg:w-[38px] max-lg:h-[38px] max-lg:ml-[22px];
  }

  .profile-picture img {
    @apply w-full h-full;
  }

  /* .profile-picture img {
    @apply absolute max-w-full max-h-full center-vertical;
  } */

  .profile-container {
    @apply ml-[12px] float-left text-left h-[41.25px] relative;
    @apply max-lg:ml-[11px] max-lg:h-[38px];
  }

  .profile-name {
    @apply text-[20.25px] text-primary leading-[25.25px] font-campton font-extrabold max-w-[125px] text-ellipsis whitespace-nowrap overflow-hidden;
    @apply 2xl:text-[22px] 2xl:leading-[1] 2xl:h-auto;
    @apply max-lg:text-[18px] max-lg:leading-[19px];
  }

  .logout {
    @apply text-[#C54A47] text-[13.5px] leading-[12.5px] font-campton font-bold absolute bottom-0 left-0;
    @apply 2xl:text-[16px] 2xl:leading-[1] 2xl:h-auto;
    @apply max-lg:text-[12px] max-lg:leading-[13px];
  }
  
  .tnc-pp {
    @apply text-[#EFBA75] underline;
  }

  .button-container {
    @apply relative float-right;
  }

  .button {
    @apply h-[37.5px] rounded-[6px] bg-gray-300 font-bold leading-[37.5px] font-campton relative;
  }

  .button-blue {
    @apply bg-[#27AAE1] text-white default-transition-effect md:hover:bg-[#F7941E] md:hover:scale-[1.1];
  }

  .button-orange {
    @apply bg-[#F7941E] text-white md:hover:scale-[1.1];
  }

  .button-logout {
    @apply bg-[#FFCBCB] text-[#F95555] text-[15px] font-campton font-extrabold shadow-[-3px_3px_1px_#E8B1B1];
  }

  .button-orange-inverted {
    @apply bg-white text-[#F7941E] hover:scale-[1.1];
  }

  .button.disabled {
    @apply bg-[#EFEFEF] text-white pointer-events-none;
  }

  .button > * {
    @apply pointer-events-none;
  }
  
  .button_bg_hover {
    @apply absolute bg-[#27AAE1] w-0 h-0 rounded-[50%] bottom-0 left-[50%] translate-x-[-50%] transition-width-height-bottom duration-[0.3s];
  }

  .button_bg_hover_light {
    @apply absolute bg-[#FDECD8] w-0 h-0 rounded-[50%] bottom-0 left-[50%] translate-x-[-50%] transition-width-height-bottom duration-[0.3s];
  }

  .button_bg_disabled {
    @apply absolute inset-0 bg-slate-200 shadow-[-2.2464px_2.9952px_#eee] rounded-[6px] opacity-70 hidden;
    @apply max-md:shadow-[-0.356vw_0.408vw_#eee];
  }

  .button-container.disabled > * {
    @apply pointer-events-none;
  }

  .button-container.disabled .button_bg_disabled{
    @apply block;
  }

  .button:not(:hover) .button_bg_hover {
    @apply md:w-0 md:h-0 md:bottom-0
  }

  .button:not(:hover) .button_bg_hover_light {
    @apply md:w-0 md:h-0 md:bottom-0
  }

  .button:hover .button_bg_hover, .button:hover .button_bg_hover_light {
    @apply md:w-[150%] md:h-[15.104vw] md:bottom-[-7.552vw];
    @apply max-lg:h-[20vw];
  }

  .popup-close-button {
    @apply absolute top-[24px] right-[24px] w-[15px] h-[24px] text-gray-400 bg-transparent text-center font-bold text-[18.75px] leading-[18.75px] cursor-pointer bg-no-repeat filter-orange;
  }

  .form-label {
    @apply font-medium text-primary text-size-primary;
  }

  .form-control {
    @apply w-full border-[#DDE3EE] h-[29.25px] text-size-primary rounded-md mt-1.5 border border-solid pl-[9.75px] py-[6.75px] focus-visible:outline-[#93D4F0] focus-visible:outline-[4px] focus-visible:outline focus-visible:border-[#27AAE1] focus-visible:border-[2px];
  }

  .form-control.error {
    @apply bg-[#FFF6F5] border-[#C54A47] border-[2px] text-[#C54A47] autofill:bg-[#FFF6F5] focus-visible:outline-[#E2A4A3] focus-visible:outline-[4px] focus-visible:outline;
  }

  .error-message {
    @apply text-[#C54A47] text-[12px] text-left;
  }

  .col {
    @apply box-border float-left px-[0.6rem];
  }

  .card {
    @apply p-12 bg-white rounded-2xl card-shadow;
  }

  .home-poster {
    @apply card px-[30px] pt-[24px] pb-[30px] max-md:rounded-none max-md:shadow-[0px_3px_8px_#4D4D4D14] max-md:mb-[24.5px];
  }

  .home-poster .share-poster-title {
    @apply font-black leading-[39px] text-primary text-[33px] text-center mb-[24px] max-w-[457.5px] mx-auto;
    @apply max-lg:text-[32px] max-lg:leading-[33px] max-lg:max-w-[541px] max-lg:mb-[32px];
    @apply max-md:text-[27px] max-md:leading-[30px] max-md:max-w-[359px];
    @apply max-xs:text-[24px] max-xs:leading-[27px] max-xs:max-w-none;
    @apply max-2xs:text-[21px] max-2xs:leading-[24px];
  }

  .home-poster .poster-container {
    @apply opacity-[0.2] default-transition-effect max-md:float-none max-md:hidden max-md:mx-auto max-md:w-auto max-md:px-0 lg:h-[291.75px];
  }

  .home-poster .poster-container.active {
    @apply opacity-[1] max-md:block;
  }

  .poster-nav {
    @apply max-md:block hidden absolute w-[20px] h-[40px] center-vertical cursor-pointer;
  }

  .poster-nav.poster-nav-prev {
    @apply left-[5vw];
    @apply max-2xs:left-[3vw];
  }

  .home-poster .poster-nav.poster-nav-prev {
    @apply max-xs:left-0;
    @apply max-2xs:left-[-10px];
  }

  .poster-nav.poster-nav-next {
    @apply right-[5vw];
    @apply max-2xs:right-[3vw];
  }

  .home-poster .poster-nav.poster-nav-next {
    @apply max-xs:right-0;
    @apply max-2xs:right-[-10px];
  }

  .poster-nav img {
    @apply filter-orange;
  }

  #home-poster-nav {
    @apply max-md:inline-block md:hidden mt-[16px];
  }
  
  .home-poster .share-container {
    @apply max-md:mt-[16px];
  }

  .home-poster .share-container .copy-link {
    @apply max-md:w-full max-md:mb-[16px] max-md:mr-0;
  }

  .home-poster .share-container .copy-link .copy-link-container {
    @apply max-md:w-[calc(100%-85px)];
    @apply max-2xs:w-[calc(100%-75px)];
  }

  .home-poster .share-container .copy-link button {
    @apply max-md:w-[85px];
    @apply max-2xs:w-[75px];
  }

  .home-poster .share-container .copy-link button img {
    @apply max-md:ml-[11.82px] max-md:mr-0 max-md:float-left;
    @apply max-2xs:w-[15px] max-2xs:h-[15px];
    @apply pointer-events-none;
  }

  .home-poster .share-container .copy-link button label {
    @apply hidden text-white max-md:block text-[15px] font-campton font-extrabold float-left ml-[4.18px] mr-[12px] cursor-pointer;
    @apply max-2xs:text-[12px];
    @apply pointer-events-none;
  }

  .home-poster .share-container .react-share__ShareButton, .home-poster .share-container button.share-more, .home-poster .share-container .download-button {
    @apply max-lg:w-[53px] max-lg:h-[53px];
    @apply max-xs:w-[48px] max-xs:h-[48px];
    @apply max-2xs:w-[43px] max-2xs:h-[43px];
  }

  .home-poster .share-container .react-share__ShareButton svg {
    @apply max-lg:w-[40px] max-lg:h-[40px];
  }

  .home-poster .share-label {
    @apply hidden font-extrabold max-md:block font-campton text-[16px] text-primary text-left leading-[53px] float-left ml-0 mr-[8px];
    @apply max-xs:mr-0;
  }

  .home-poster .label-generate-poster {
    @apply text-primary font-campton text-[13.5px] leading-[13.5px] font-bold text-center;
    @apply max-lg:text-[18px] max-lg:leading-[18px];
    @apply max-md:text-[17px] max-md:leading-[17px];
    @apply max-xs:text-[14px] max-xs:leading-[14px];
  }

  .home-poster .link-generate-poster {
    @apply max-lg:text-[18px] max-lg:leading-[18px] max-lg:hover:text-[19px];
    @apply max-md:text-[17px] max-md:leading-[17px];
    @apply max-xs:text-[14px] max-xs:leading-[14px];
  }

  .poster {
    @apply w-full mx-auto rounded-lg cursor-pointer border-solid border border-[#9B9898];
    @apply max-md:max-w-[277px] max-md:h-[369px];
  }

  .poster.poster-1{
    @apply bg-[#28265D]
  }

  .poster.poster-2{
    @apply bg-[#27AAE1]
  }

  .poster.active, .customise-poster-content .poster:not(.hidden) {
    @apply border-[#25225E] border-4;
  }

  .poster-img {
    @apply rounded-[10px]
  }

  .poster-img.active {
    @apply pt-[2.5px]
  }

  .tab-container {
    @apply max-md:ml-[31px] max-md:mr-[35.24px];
  }

  .tab-button-container {
    @apply mb-[17.75px];
  }

  .tab-content-container .card {
    @apply min-h-[524px];
  }

  .tab-item {
    @apply bg-[#E0E2E2] text-[#827E7E] font-bold float-left text-[20.25px] rounded-[44px] mr-[12px] px-[16.5px] w-[195px] h-[56.25px] leading-[56.25px] cursor-pointer;
    @apply max-lg:w-[calc(50%-12px)] max-lg:ml-[24px] max-lg:mr-0 max-lg:h-[75px] max-lg:text-[27px] max-lg:leading-[75px];
    @apply max-md:w-[calc(50%-5.5px)] max-md:ml-[11px] max-md:mr-0 max-md:h-[51px] max-md:text-[18px] max-md:leading-[51px];
    @apply max-sm:text-[15px];
    @apply max-xs:text-[15px];
    @apply max-2xs:text-[12px];
  }

  .tab-item:first-child {
    @apply max-lg:ml-0;
  }

  .tab-item.active {
    @apply font-extrabold bg-white text-secondary;
  }

  .prize-image {
    @apply float-left text-center w-[36px] mr-[12.42px];
    @apply max-lg:w-[48px] max-lg:mr-[16.56px];
    @apply max-md:w-auto max-md:mb-[10px] max-md:mr-[11.79px] max-md:mt-[5.5px];
  }

  .prize-image img {
    @apply w-[28.5px] h-[25.5px] my-0 mx-auto cursor-pointer;
    @apply max-lg:w-[38px] max-lg:h-[34px];
    @apply max-md:w-[38px] max-md:h-[34px];
    @apply max-2xs:w-[30px] max-2xs:h-[26px];
  }

  .prize-image label {
    @apply text-primary font-campton font-extrabold text-[13.5px] text-center leading-[13.5px] cursor-pointer;
    @apply max-lg:text-[18px] max-lg:leading-[18px];
    @apply max-md:text-[16px];
    @apply max-2xs:text-[12px];
  }

  .prize-item {
    @apply mb-[14.25px] last:overflow-hidden relative;
    @apply max-lg:mb-[19px];
  }

  .prize-detail-container {
    @apply bg-[#F4F7FC] rounded-[10.5px] pt-[15px] pl-[17.805px] pr-[11.625px] pb-[15px] h-[69px] float-right w-[calc(100%-62.25px)] relative overflow-hidden;
    @apply max-lg:pt-[20px] max-lg:pl-[23.75px] max-lg:pr-[15.5px] max-lg:pb-[20px] max-lg:h-[92px] max-lg:w-[calc(100%-83px)];
    @apply max-md:h-auto max-md:pt-[16px];
  }

  .prize-detail-container .icon-redeemed {
    @apply h-[80px] absolute right-[-5px] bottom-[-7px] opacity-[0.25];
    @apply max-lg:h-[110px] max-lg:right-[-10px] max-lg:bottom-[-10px];
    @apply max-md:h-[75px] max-md:right-[-5px] max-md:bottom-[-3px];
    @apply max-2xs:h-[60px] max-2xs:bottom-[-5px];
  }

  .prize-detail-container.redeemed {
    @apply bg-[#E8F5EA];
  }

  .prize-item:last-child, .referral-item:last-child {
    @apply mb-0;
  }

  .prize-details, .prize-detail {
    @apply float-left;
  }

  .prize-details {
    @apply w-[calc(100%-48.42px)] max-lg:w-[calc(100%-64.56px)] max-md:w-[calc(100%-53.76px)];
  }

  .prize-details .button-container {
    @apply max-md:float-left max-md:mt-[13px];
  }

  .prize-details .button-container.disabled > .button {
    @apply pointer-events-auto scale-[1] shadow-[-2.2464px_2.9952px_#E57B41] cursor-default;
  }

  .prize-details .button-container.disabled > .button:hover .button_bg_hover {
    @apply bottom-0 w-0 h-0;
  }

  .prize-details .button-container.disabled > .button.button-orange.transition:active {
    @apply bg-[#F7941E];
  }

  .prize-detail {
    @apply max-md:float-none max-md:w-full;
  }

  .prize-detail .title {
    @apply font-extrabold text-primary text-[20.25px] leading-[22.5px] text-left font-campton mb-[3px] cursor-pointer;
    @apply max-lg:text-[27px] max-lg:leading-[30px] max-lg:mb-[4px];
    @apply max-md:text-[18px] max-md:mb-0;
    @apply max-2xs:text-[12px];
  }

  .prize-detail .requirement {
    @apply text-[#27AAE1] text-[13.5px] font-medium text-left cursor-pointer;
    @apply max-lg:text-[18px];
    @apply max-md:text-[16px] max-md:font-campton;
    @apply max-2xs:text-[10px];
  }

  .prize-detail-container.redeemed .prize-image label, .prize-detail-container.redeemed .prize-detail .title, .prize-detail-container.redeemed .prize-detail .requirement {
    @apply text-[#58B17B];
  }

  .prize-progress {
    @apply float-left w-[50.25px] font-campton font-extrabold text-[13.5px] relative h-[69px] mr-[12px] text-center;
    @apply max-lg:w-[67px] max-lg:text-[18px] max-lg:h-[92px] max-lg:mr-[16px];
    @apply max-md:absolute max-md:top-0 max-md:bottom-0 max-md:h-auto;
  }

  .prize-progress .start-label {
    @apply text-[#58B17C] text-[13.5px] absolute top-0 center-horizontal;
    @apply max-lg:text-[18px];
    @apply max-md:top-[calc(50%-45px)];
  }

  .prize-progress .goal-label-container {
    @apply max-md:absolute max-md:w-full max-md:top-[calc(50%+8px)] max-md:h-[200px] max-md:bg-white;
  }

  .prize-progress .goal-label {
    @apply text-[#51A6DA] text-[13.5px] absolute bottom-0 center-horizontal;
    @apply max-lg:text-[18px];
    @apply max-md:top-[7px];
  }

  .prize-progress .circle-progress {
    @apply w-[17.25px] h-[17.25px] border-solid border-[2px] border-[#B4B4B4] rounded-full absolute middle-div z-10 bg-white;
    @apply max-lg:w-[23px] max-lg:h-[23px];
  }

  .prize-progress .circle-progress.available {
    @apply border-[#58B17C];
  }

  .prize-progress .circle-progress.redeemed {
    @apply border-[#58B17C] bg-[#58B17C];
  }

  .prize-progress .line-progress {
    @apply bg-[#B4B4B4] h-[45px] w-[2px] mx-auto absolute top-[50%] center-horizontal h-[75px];
    @apply max-lg:h-[100px];
    @apply max-md:h-[200px];
  }

  .prize-progress .line-progress.active {
    @apply bg-[#58B17C];
  }

  .referral-next-reward {
    @apply bg-[#E5F3F7] rounded-full p-[9px] mb-[12px] ml-[20px] relative font-campton text-[13.5px] h-[38.25px];
    @apply max-lg:text-[18px] max-lg:h-[51px];
    @apply max-md:text-[16px] max-md:ml-[0px] max-md:h-[68px];
    @apply max-xs:text-[15px];
  }

  .referral-next-reward .icon-next-referral{
    @apply h-[51.9px] w-[45.09px] float-left absolute bottom-0 left-[-20px];
    @apply max-lg:h-[69.2px] max-lg:w-[60.12px] max-lg:left-[-25px];
    @apply max-md:h-[83.05px] max-md:w-[72.8px] max-md:left-[-7px];
  }

  .referral-next-reward .next-reward-label {
    @apply relative h-[100%] w-[100%] text-primary;
    @apply max-md:ml-[55px] max-md:w-[calc(100%-70px)] max-md:leading-[19px] max-md:text-left;
  }

  .referral-next-reward .next-reward-label .vertical-center{
    @apply m-[0px] w-[100%] absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%];
  }

  .referral-item {
    @apply bg-[#F4F7FC] rounded-[10.5px] py-[12px] pl-[12px] pr-[6px] mb-[12px] relative overflow-hidden;
    @apply max-lg:p-[16px] max-lg:mb-[16px];
    @apply max-md:min-h-[112.6px];
  }

  .referral-item.success {
    @apply bg-[#E8F5EA] rounded-[10.5px] py-[12px] pl-[12px] pr-[6px] mb-[12px];
    @apply max-lg:p-[16px] max-lg:mb-[16px];
    @apply max-md:p-[12px] max-md:mb-[12px];
  }

  .referral-item .button-view-detail {
    @apply bg-opacity-0 text-[#262262] border-[#262262] border-solid border-[2px] rounded-[9px] pt-[3.25px] sm:hover:scale-y-[1.1] transform transition duration-500 font-campton font-bold text-[11.25px] leading-[11.25px] mt-[21.75px] w-[90.795px] h-[33.75px] cursor-pointer;
    @apply max-lg:text-[15px] max-lg:leading-[15px] max-lg:w-[121.06px] max-lg:h-[45px] max-lg:pt-[8px];
    @apply max-md:absolute max-md:top-[38px] max-md:text-[11.25px] max-md:leading-[11.25px] max-md:right-[7px] max-md:w-[89.76px] max-md:h-[33.75px] max-md:pt-[4px] max-md:mt-[29px];
  }

  .referral-item .button-view-detail .button-label-pc {
    @apply inline-block cursor-pointer;
    @apply max-md:hidden;
  }

  .referral-item .button-view-detail .button-label-mobile {
    @apply hidden;
    @apply max-md:inline-block;
  }

  .referral-item .button-view-detail p {
    @apply text-[#262262] font-campton font-bold text-[11.25px] leading-[11.25px] mt-[7px] transform transition duration-500 cursor-pointer;
    @apply max-lg:text-[15px] max-lg:leading-[15px];
    @apply max-md:text-[11.25px] max-md:leading-[11.25px];
  }

  .referral-item .button-view-detail:hover p {
    @apply scale-x-[1.1];
  }

  .referral-short-detail {
    @apply float-left w-[calc(100%-100px)];
    @apply max-lg:w-[calc(100%-133.33px)];
    @apply max-md:w-[100%];
  }

  .referral-short-detail .referral-name {
    @apply font-bold font-campton text-primary text-[20.25px] leading-[20.25px] text-left mb-[8.25px] max-w-[100%] text-ellipsis overflow-hidden whitespace-nowrap;
    @apply max-lg:text-[27px] max-lg:leading-[27px] max-lg:mb-[11px];
    @apply max-md:text-[20.25px] max-md:leading-[20.25px] max-md:mb-[8.25px];
    @apply max-xs:text-[20.25px] max-xs:leading-[20.25px] max-xs:mb-[8.25px];
  }

  .referral-short-detail .referral-name.success {
    @apply font-bold font-campton text-[#58B17B] text-[20.25px] leading-[20.25px] text-left mb-[8.25px];
    @apply max-lg:text-[27px] max-lg:leading-[27px] max-lg:mb-[11px];
    @apply max-md:text-[20.25px] max-md:leading-[20.25px] max-md:mb-[8.25px];
    @apply max-xs:text-[20.25px] max-xs:leading-[20.25px] max-xs:mb-[8.25px];
  }

  .referral-short-detail .referral-short-desc {
    @apply max-md:w-[calc(100%-95.76px)];
  }

  .referral-short-detail .referral-short-desc p {
    @apply font-campton text-[13.5px] text-[#27AAE1] font-medium text-left mt-[8.25px];
    @apply max-lg:text-[18px] max-lg:mt-[11px];
    @apply max-xs:text-[12.5px];
  }

  .referral-short-detail .referral-short-desc.success p {
    @apply font-campton text-[13.5px] text-[#58B17B] font-medium text-left mt-[8.25px];
    @apply max-lg:text-[18px] max-lg:mt-[11px];
    @apply max-xs:text-[12.5px];
  }

  .referral-item .icon-referral-success {
    @apply h-[92px] absolute right-[-11px] bottom-[1px] opacity-[0.25];
    @apply max-lg:h-[122.67px] max-lg:bottom-[-4px];
    @apply max-md:h-[65px] max-md:right-[-3px];
  }

  .progress-bar-container {
    @apply h-[9.75px];
    @apply max-lg:h-[13px];
    @apply max-md:h-[9.75px];
  }

  .progress-bar {
    @apply h-[9.75px] w-[294.5px] rounded-full;
    @apply max-lg:h-[13px] max-lg:w-[393px];
    @apply max-md:h-[9.75px] max-md:w-[294.5px];
  }

  .progress-bar-body {
    @apply bg-[#FFE2BF] float-left w-[calc(100%-50px)];
    @apply max-lg:w-[calc(100%-66.67px)];
    @apply max-md:max-w-[calc(100%-50px)] max-md:w-[calc(100%-50px)];
  }

  .progress-bar-value {
    @apply bg-[#F7941E];
  }

  .progress-bar-value.success {
    @apply bg-[#58B17B];
  }

  .progress-detail {
    @apply float-right text-secondary font-bold ml-[19.5px] text-[13.5px] leading-[13.5px] mt-[-2px];
    @apply max-lg:ml-[16px] max-lg:text-[18px] max-lg:leading-[18px];
    @apply max-md:ml-[14px] max-md:text-[13px] max-md:leading-[13px];
  }

  .progress-detail.success {
    @apply float-right text-[#58B17B] font-bold ml-[19.5px] text-[13.5px] leading-[13.5px] mt-[-2px];
    @apply max-lg:ml-[16px] max-lg:text-[18px] max-lg:leading-[18px];
    @apply max-md:ml-[8px] max-md:text-[13px] max-md:leading-[13px];
  }

  .detail-number {
    @apply float-left;
  }

  .check-circle {
    @apply border-[3px] border-solid border-[#33873C] rounded-full text-center font-extrabold text-[18.75px] text-[#B4B4B4] w-[33.75px] h-[33.75px] leading-[30px] bg-[#33873C];
    @apply max-md:w-[45px] max-md:h-[45px];
  }

  .check-circle img{
    @apply filter-white w-[18.75px] mx-auto mt-[5.5px];
    @apply max-md:w-[25px] max-md:mt-[7px];
  }

  .number-circle {
    @apply border-[3px] border-solid border-[#B4B4B4] rounded-full text-center font-extrabold text-[18.75px] text-[#B4B4B4] w-[33.75px] h-[33.75px] leading-[30px];
    @apply max-md:w-[45px] max-md:h-[45px] max-md:pt-[5px] max-md:text-[25px];
  }

  .next-line {
    @apply bg-[#B4B4B4] h-[33.75px] w-[3px] mx-auto;
    @apply max-md:h-[32px];
  }

  .detail-desc {
    @apply text-left text-[#B4B4B4] ml-[14px] text-[13.5px] float-left max-w-[80%] tracking-normal w-[222px] absolute top-[50%] translate-y-[-50%];
    @apply max-md:ml-[15.5px] max-md:text-[16px] leading-[19px];
  }

  .detail-desc-container {
    @apply h-[67.5px] relative translate-y-[-25%];
    @apply max-md:h-[77px] max-md:translate-y-[-22.5%];
  }

  .progress-detail-container {
    @apply mb-[-33.75px];
    @apply max-md:mb-[-24x];
  }

  .progress-detail-item.active .number-circle {
    @apply text-[#33873C] border-[#33873C];
  }

  .progress-detail-item.active .detail-desc {
    @apply text-[#33873C];
  }

  .next-line.active {
    @apply bg-[#33873C];
  }

  .progress-detail-item.next-goal .number-circle {
    @apply text-primary border-[#262262];
  }

  .progress-detail-item.next-goal .detail-desc {
    @apply text-primary text-[18px] font-extrabold leading-[22px];
  }

  .next-line.next-goal {
    @apply bg-[#33873C];
  }

  .popup-overlay {
    @apply bg-[#333333] bg-opacity-50;
  }

  .share-container {
    @apply mt-[24px];
  }

  .share-container .copy-link {
    @apply float-left w-[249px] cursor-pointer mr-[5px];
    @apply max-lg:w-[360px];
    @apply max-md:w-[270px];
  }

  .share-container .copy-link .copy-link-container {
    @apply w-[205.5px] h-[39.75px] border-solid border border-[#F7941E] float-left rounded-l-[7.5px] p-[13.5px] default-transition-effect cursor-pointer;
    @apply max-lg:h-[53px] max-lg:w-[calc(100%-58px)];
    @apply max-md:h-[39.75px] max-md:w-[calc(100%-39.75px)];
  }

  .share-container .copy-link .copy-link-container p {
    @apply text-[#707070] font-campton font-bold text-[13.5px] opacity-[0.52] text-left text-ellipsis whitespace-nowrap overflow-hidden leading-[1];
    @apply max-lg:text-[18px] max-lg:leading-[24px];
    @apply max-md:text-[13.5px] max-md:leading-[1];
  }

  .share-container .copy-link button {
    @apply w-[43.5px] h-[39.75px] bg-[#F7941E] float-right rounded-r-[7.5px] default-transition-effect;
    @apply max-lg:h-[53px] max-lg:w-[58px];
    @apply max-md:h-[39.75px] max-md:w-[43.5px];
  }
  
  .share-container .copy-link button img, .share-container .share-more img, .customise-poster-content .share-buttons .copy-link img, .share-container .download-button img {
    @apply w-[18px] h-[20.25px] filter-white ml-auto mr-auto default-transition-effect;
  }

  .share-container .copy-link:hover .copy-link-container, .share-container .copy-link:hover button {
    @apply md:scale-y-[1.1];
  }

  .share-container .copy-link:hover button img {
    @apply md:scale-x-[1.1];
  }

  .share-container .react-share__ShareButton, .share-container .share-more, .customise-poster-content .copy-text-share-container .react-share__ShareButton, .customise-poster-content .share-buttons .copy-link, .share-container .download-button {
    @apply float-left ml-[6px] w-[39.75px] h-[39.75px] bg-[#F7941E] rounded-full default-transition-effect md:hover:scale-[1.1];
  }

  .customise-poster-content .copy-text-share-container .react-share__ShareButton, .customise-poster-content .share-buttons .copy-link {
    @apply max-lg:w-[53px] max-lg:h-[53px];
  }

  .customise-poster-content .copy-text-share-container .react-share__ShareButton[aria-label="whatsapp"] svg {
    @apply max-lg:w-[45px] max-lg:h-[45px];
  }

  .customise-poster-content .copy-text-share-container .react-share__ShareButton[aria-label="facebook"] svg {
    @apply max-lg:w-[50.75px] max-lg:h-[50.75px];
  }

  .customise-poster-content .copy-text-share-container .react-share__ShareButton[aria-label="linkedin"] svg {
    @apply max-lg:w-[50.75px] max-lg:h-[50.75px];
  }

  .share-container .react-share__ShareButton svg, .customise-poster-content .copy-text-share-container .react-share__ShareButton svg {
    @apply ml-auto mr-auto;
    @apply pointer-events-none;
  }

  .share-container button.share-more {
    @apply bg-[#E0E2E2];
  }

  .share-container button.share-more img {
    @apply filter-gray;
  }

  .share-container .download-button img {
    @apply w-[12.75px] h-[17-25px] max-lg:w-[25px] max-lg:h-[25px];
    @apply pointer-events-none;
  }

  .share-container-popup .react-share__ShareButton {
    @apply w-full h-[45px] rounded-[10px] inline-block mt-[12px] items-center justify-between;
  }

  .share-container-popup .react-share__ShareButton img {
    @apply h-[25px] inline-block mt-[-5px];
  }

  .share-container-popup .react-share__ShareButton img#li-icon {
    @apply h-[20px] inline-block mt-[-6px];
  }

  .share-container-popup #copy-icon, .share-container-popup #download-icon {
    @apply h-[17.5px] inline-block mt-[-3px] filter-white;
  }

  .share-container-popup .react-share__ShareButton[aria-label="whatsapp"] {
    @apply bg-[#2FB93E] shadow-[-2.2464px_2.9952px_#189B26] hover:scale-y-[1.1] default-transition-effect;
  }

  .share-container-popup .react-share__ShareButton[aria-label="facebook"] {
    @apply bg-[#4267B2] shadow-[-2.2464px_2.9952px_#335395] hover:scale-y-[1.1] default-transition-effect;
  }

  .share-container-popup .react-share__ShareButton[aria-label="linkedin"] {
    @apply bg-[#0E76A8] shadow-[-2.2464px_2.9952px_#025983] hover:scale-y-[1.1] default-transition-effect;
  }

  .separator {
    @apply my-[24px] border-solid border-b border-b-[#54A8DC];
  }

  .link-generate-poster {
    @apply text-secondary font-campton text-[13.5px] leading-[13.5px] font-extrabold text-center underline default-transition-effect md:hover:text-[14.25px] md:hover:text-[#27AAE1];
  }

  .customise-poster-content .title {
    @apply text-primary font-extrabold text-[22.5px] mb-[12px] h-[27px] text-left;
    @apply max-lg:text-[30px] max-lg:mb-[16px] max-lg:h-[36px];
    @apply max-md:text-[18px] max-md:mb-[0px];
    @apply max-xs:text-[14px] max-xs:mt-[4px];;
  }

  .customise-poster-content .poster-selection .title {
    @apply text-center;
  }

  .customise-poster-content .child-picture-note {
    @apply text-primary font-campton text-[15.75px] text-left mb-[12px] mt-[-4px] h-[70.5px];
    @apply max-lg:text-[21px] max-lg:mb-[16px] max-lg:h-[94px];
    @apply max-md:max-w-[362px] max-md:text-[17px] max-md:h-auto max-md:mb-[16px];
    @apply max-2xs:text-[12px] max-2xs:max-w-[285px];
  }

  .customise-poster-content {
    @apply max-w-[828px] w-full ml-auto mr-auto pt-[126px] pb-[65px];
    @apply max-lg:max-w-[609px] max-lg:pt-[94.75px] max-lg:pb-[108.5px];
    @apply max-md:pt-[87px] max-md:pb-[121px];
  }

  .customise-poster-content .customise-poster-title {
    @apply text-primary font-extrabold text-[33px] text-center leading-[33px] mb-[27.375px] mt-[5.625px];
    @apply max-lg:text-[32px] max-lg:mb-[31.75px] max-lg:mt-0;
    @apply max-md:max-w-[364px] max-md:mx-auto max-md:mb-[24px];
    @apply max-2xs:text-[28px] max-2xs:max-w-[285px];
  }

  .customise-poster-content .poster-selection {
    @apply float-left w-[324px] mr-[46.5px];
    @apply max-lg:mx-auto max-lg:float-none max-lg:mb-[31.75px];
    @apply max-md:float-none max-md:mb-[16px];
  }

  .customise-poster-content .child-picture-share {
    @apply float-left w-[456.75px];
    @apply max-lg:w-auto;
    @apply max-md:float-none max-md:w-full max-md:px-[32px];
  }

  .customise-poster-content .poster-selection {
    @apply max-md:w-full max-md:mr-0;
  }

  .customise-poster-content .poster {
    @apply w-full rounded-[10px] pointer-events-none;
  }

  .customise-poster-content .poster-selection-nav {
    @apply ml-auto mr-auto w-[148.5px] relative mt-[12px];
  }

  .customise-poster-content .poster-selection-nav .nav {
    @apply filter-orange absolute w-[15px] h-[24px] cursor-pointer default-transition-effect top-0;
  }

  .customise-poster-content .poster-selection-nav .nav:hover {
    @apply filter-blue;
  }

  .customise-poster-content .poster-selection-nav .nav.nav-prev {
    @apply left-0 max-md:hidden;
  }

  .customise-poster-content .poster-selection-nav .nav.nav-next {
    @apply right-0 max-md:hidden;
  }

  .customise-poster-content .poster-selection-nav .nav-points, .home-poster .nav-points {
    @apply inline-block ml-auto mr-auto;
  }

  .customise-poster-content .poster-selection-nav .nav-point, .home-poster .nav-point {
    @apply w-[10.5px] h-[10.5px] rounded-full bg-[#B6B6B6] float-left ml-[3px] cursor-pointer default-transition-effect;
    @apply max-md:w-[8px] max-md:h-[8px];
  }

  .customise-poster-content .poster-selection-nav .nav-point.active, .home-poster .nav-point.active {
    @apply bg-[#262262];
  }

  .customise-poster-content .poster-selection-nav .nav-point:first-child, .home-poster .nav-point:first-child {
    @apply ml-0;
  }

  .customise-poster-content .upload-photo-container {
    @apply relative mb-[19.5px];
    @apply max-lg:mb-[17px];
  }

  .customise-poster-content .button-child-photo {
    @apply w-[72.75px] h-[72.75px] rounded-[13px] bg-[#FFC278] float-left mr-[12.75px] cursor-pointer relative md:hover:bg-[#F7941E];
    @apply max-lg:w-[97px] max-lg:h-[97px] max-lg:mr-[17px];
    @apply max-md:mr-[16px] max-md:w-[calc(33%-9.5px)] max-md:h-[calc(33%-9.5px)] max-sm:w-[calc(33%-10.5px)] max-sm:h-[calc(33%-10.5px)];
  }

  .customise-poster-content .button-child-photo.button-upload {
    @apply max-md:mr-0;
  }

  .customise-poster-content .button-child-photo .child-photo-container {
    @apply overflow-hidden rounded-[13px];
  }

  .customise-poster-content .button-child-photo .child-photo-container.icon-container {
    @apply max-md:pt-[100%];
  }

  .customise-poster-content .button-child-photo.active .child-photo-container {
    @apply border-[#262262] border-[4px];
  }

  .btn-delete-child-photo {
    @apply absolute w-[20px] h-[20px] top-[-5px] right-[-5px] bg-[#C54A47] px-[6px] py-[4.5px] rounded-full cursor-pointer border border-solid border-[#E5F3F7];
    @apply max-md:w-[26px] max-md:h-[26px] max-md:px-[8px] max-md:py-[6.5px];
  }

  .btn-delete-child-photo img {
    @apply filter-white;
  }

  .customise-poster-content .button-child-photo img.icon {
    @apply w-[19.5px] h-[22.5px] middle-div filter-white absolute;
  }

  /* .customise-poster-content .share-text-title {
    @apply mb-[12px];
  } */

  .customise-poster-content .regenerate-suggestions {
    @apply float-right text-secondary font-extrabold text-[18px] leading-[33.75px] cursor-pointer hover:text-[#27AAE1] md:hover:underline;
    @apply max-md:lowercase max-md:mt-[-4px];
    @apply max-xs:text-[14px];
  }

  .customise-poster-content .regenerate-suggestions img {
    @apply w-[22px] h-[33.75px] filter-orange float-left mr-2;
    @apply max-xs:w-[17.11px] max-xs:h-[26.25px] max-xs:mt-[4px];
  }

  .customise-poster-content .regenerate-suggestions:hover img {
    @apply filter-blue;
  }

  .customise-poster-content .share-text-input {
    @apply w-full h-[175.5px] rounded-[10px] bg-white overflow-hidden mb-[13.875px] border-[2px] border-solid border-[#DDE3EE];
    @apply focus-within:outline-[#93D4F0] focus-within:outline-[4px] focus-within:outline focus-within:border-[#27AAE1] focus-within:border-[2px];
    @apply max-lg:h-[234px];
    @apply max-md:mb-[16px];
  }

  .customise-poster-content .share-text-input textarea {
    @apply w-full h-[132.25px] text-primary font-campton text-[15.75px] text-left px-[18px] pt-[18px] pb-[12px] resize-none focus-visible:outline-0;
    @apply max-lg:text-[21px] max-lg:h-[190.75px];
    @apply max-md:text-[15.75px];
    @apply max-md:text-[13.25px];
  }

  .customise-poster-content .letter-counter {
    @apply text-right font-campton text-[15px] text-[#817E7E] h-[21px] font-bold px-[18px] mb-[12px];
  }

  .customise-poster-content .copy-text-share-container {
    @apply relative mt-[-5px] max-md:mt-0 max-md:h-[31.6px];
  }

  .customise-poster-content .share-buttons {
    @apply float-right max-md:hidden;
  }

  .customise-poster-content .share-buttons .copy-link {
    @apply bg-[#F7941E]
  }

  .customise-poster-content .share-buttons .copy-link img {
    @apply filter-white w-[21.75px] h-[17.25px];
    @apply max-lg:w-[29px] max-lg:h-[23px];
    @apply pointer-events-none;
  }

  .sticky-share-button {
    @apply md:hidden fixed w-full bg-[#262262] px-[35px] pt-[16px] pb-[28px];
  }

  .active-referral-title-container {
    @apply ml-[24px] mr-[57px]
  }

  .active-referral-title-container p {
    @apply text-left text-primary pt-[24px] font-black text-[24px] pb-[24px] leading-[1.2] max-w-xs 
  }

  .toast {
    @apply fixed center-horizontal bottom-[68.25px] bg-[#333333] rounded-[16px] max-w-[90%] px-[18px] py-[14.25px] text-white min-w-[264.75px] z-[999];
  }

  .toast.success {
    @apply bg-[#33873C];
  }

  .toast.warning {
    @apply bg-[#FED00B];
  }

  .toast.error {
    @apply bg-[#CC4B47];
  }

  .toast.warning .toast-button, .toast.warning .toast-message {
    @apply text-[#333333];
  }

  .toast-icon {
    @apply absolute left-[18px] center-vertical w-[15px] h-[15px];
  }

  .toast.success .toast-icon img, .toast.error .toast-icon img {
    @apply filter-white;
  }

  .toast-message {
    @apply text-[12px] leading-[12px] pl-[27px] pr-[69px];
  }

  .toast-button {
    @apply absolute center-vertical underline right-[18px] text-[12px] font-bold font-campton cursor-pointer;
  }

  .season-countdown-container > p {
    @apply float-left font-campton text-[15.75px] text-primary w-[254px] leading-[18.75px] text-left font-bold;
    @apply max-lg:text-[21px] max-lg:leading-[24px] max-lg:w-[calc(100%-275px)];
    @apply max-md:text-[18px] max-md:text-center max-md:float-none max-md:w-full max-md:mb-[12.13px];
    @apply max-2xs:text-[14px] max-2xs:leading-[16px];
  }

  .season-countdown-container .countdown-container {
    @apply bg-[#E5F3F7] rounded-full w-[192px] float-right h-[57px] relative;
    @apply max-lg:w-[256px] max-lg:h-[76px];
    @apply max-md:w-[calc(100%-8px)] max-md:float-none max-md:h-[52px] max-md:max-w-[315.5px] max-md:mx-auto;
  }

  .season-countdown-container .time-content {
    @apply pt-[7.5px] pl-[39.75px] pr-[24.75px];
    @apply max-lg:pt-[10px] max-lg:pl-[50px] max-lg:pr-[33px];
    @apply max-md:pt-0 max-md:pl-[38px];
    @apply max-2xs:pl-[38px];
  }

  .season-countdown-container .season-countdown-label-container {
    @apply max-md:float-left max-md:max-w-[90px] max-md:h-[52px] max-md:relative;
    @apply max-xs:max-w-[68px];
  }

  .season-countdown-container .season-countdown-label {
    @apply font-extrabold text-center text-primary font-campton text-[12.5px] leading-[12.5px];
    @apply max-lg:text-[18px] max-lg:leading-[18px];
    @apply max-md:text-[16px] max-md:text-center max-md:leading-[18px] max-md:absolute max-md:center-vertical;
    @apply max-xs:text-[12px] max-xs:leading-[14px];
  }

  .season-countdown-container .time-countdown {
    @apply max-md:ml-[102px] max-md:relative max-md:h-[52px];
    @apply max-xs:ml-[80px];
  }

  .season-countdown-container .time-container {
    @apply max-md:h-[35px] max-md:absolute max-md:center-vertical;
    @apply max-xs:h-[30px];
  }

  .season-countdown-container .time-item {
    @apply float-left;
  }

  .season-countdown-container .time-item.time-separator {
    @apply mx-[5px] max-md:ml-[2px];
  }

  .season-countdown-container .time-item:first-child {
    @apply mr-[15px] ml-[5px];
    @apply max-lg:mr-[29px];
    @apply max-md:ml-0 max-md:mr-[17.53px];
    @apply max-2xs:mr-[12px];
  }

  .season-countdown-container .time-number {
    @apply text-primary text-[13.5px] leading-[13.5px] text-center my-[2px] font-campton;
    @apply max-lg:text-[18px] max-lg:leading-[18px];
    @apply max-md:text-[16px];
    @apply max-xs:text-[12px];
  }

  .season-countdown-container .time-unit {
    @apply text-center text-[#27AAE1] font-campton text-[9px];
    @applu max-lg:text-[12px];
    @apply max-md:text-[12px];
    @apply max-xs:text-[8px];
  }

  .season-countdown-container .time-image {
    @apply w-[46.32px] h-[53.01px] absolute translate-x-[-30%];
    @apply max-lg:w-[61.76px] max-lg:h-[70.68px];
    @apply max-md:h-[57.29px] max-md:w-[50.05px] max-md:top-[-3px] max-md:translate-x-[-33%];
  }

  .active-referral-container {
    @apply max-h-[691px] overflow-y-hidden p-[18px];
    @apply max-md:p-[16px];
  }

  .referral-popup-button {
    @apply pt-[18px] pr-[24px] pb-[27px] pl-[28.31px] font-campton;
    @apply max-md:pt-[24px] max-md:pr-[24.75px] max-md:pb-[27px] max-md:pl-[27px];
  }

  .referral-popup-button .button-label-pc {
    @apply inline-block;
    @apply max-md:hidden;
  }

  .referral-popup-button .button-label-mobile {
    @apply hidden;
    @apply max-md:inline-block max-md:text-[15px] max-md:font-bold;
  }

  .referral-detail-student-name {
    @apply float-left pr-[7px] max-w-[245px] text-ellipsis overflow-hidden whitespace-nowrap;
  }

  .faq-container {
    @apply pt-[8.75vw] pb-[65px] max-w-[810px] mx-auto;
    @apply max-md:pt-[87px] max-md:pb-[16px] max-md:min-h-[calc(100vh-118px)];
  }

  .faq-page-title {
    @apply text-primary font-extrabold text-[33px] text-center leading-[33px] mb-[27.375px] mt-[5.625px];
    @apply max-md:mx-auto max-md:mb-[24px] max-md:ml-[32px] max-md:mr-[34px] max-md:text-[27px] max-md:mb-[15px];
  }

  .faq-item {
    @apply mb-[18px];
    @apply max-md:ml-[32px] max-md:mr-[34px] max-md:mb-[16px];
  }

  .faq-item:last-child {
    @apply mb-0;
  }

  .faq-title {
    @apply bg-white relative rounded-[10px] pl-[18px] pr-[69px] py-[21px] cursor-pointer;
    @apply max-md:p-[16px] max-md:pr-[62px];
  }

  .faq-title p {
    @apply text-left text-primary font-extrabold text-[20.25px];
    @apply max-md:text-[18px];
  }

  .faq-title img {
    @apply absolute w-[26.25px] h-[30px] right-[18px] filter-orange top-[50%] translate-y-[-50%] rotate-180 default-transition-effect;
    @apply max-md:w-[21px] max-md:h-[24px];
  }

  .faq-title.open img {
    @apply rotate-0;
  }

  .faq-content-container {
    transition: max-height 0.3s;
    @apply overflow-hidden max-h-0;
  }

  .faq-content-container.open {
    @apply max-h-[1000px];
  }

  .faq-content {
    @apply bg-white rounded-[10px] p-[18px] mt-[18px];
    @apply max-md:p-[16px] max-md:mt-[18px];
  }

  .faq-content p {
    @apply text-left text-primary font-campton text-[15.75px];
    @apply max-md:text-[16px];
  }

  .page-not-found-container {
    @apply max-md:min-h-[calc(100vh-118px)];
  }

  .share-container-buttons {
    @apply float-left;
    @apply max-md:float-none max-md:w-fit max-md:mx-auto;
  }

  .popup-card {
    @apply relative w-full h-full max-w-[320.4375px] mx-auto;
    @apply md:h-auto md:min-w-[320.4375px];
    @apply max-md:max-w-none max-md:w-[320.4375px];
    @apply max-sm:max-w-[90vw] max-sm:px-0;
  }

  .disabled-prize-popup-message {
    @apply text-center font-bold text-[18px] mb-[24.75px];
  }

  .disabled-prize-popup-note {
    @apply text-center font-normal italic text-[18px];
  }
}