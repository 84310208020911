@tailwind utilities;
@layer utilities {
    .lds-grid {
        @apply self-center inline-block relative w-[80px] h-[80px];
      }
      .lds-grid div {
        animation: lds-grid 1.2s linear infinite;
        @apply absolute w-4 h-4 rounded-[50%] bg-white;
      }
      .lds-grid div:nth-child(1) {
        animation-delay: 0s;
        @apply top-2 left-2;
      }
      .lds-grid div:nth-child(2) {
        animation-delay: -0.4s;
        @apply top-2 left-8;
      }
      .lds-grid div:nth-child(3) {
        animation-delay: -0.8s;
        @apply top-2 left-14;
      }
      .lds-grid div:nth-child(4) {
        animation-delay: -0.4s;
        @apply top-8 left-2;
      }
      .lds-grid div:nth-child(5) {
        animation-delay: -0.8s;
        @apply top-8 left-8;
      }
      .lds-grid div:nth-child(6) {
        animation-delay: -1.2s;
        @apply top-8 left-14;
      }
      .lds-grid div:nth-child(7) {
        top: 56px;
        left: 8px;
        animation-delay: -0.8s;
        @apply top-14 left-2;
      }
      .lds-grid div:nth-child(8) {
        animation-delay: -1.2s;
        @apply top-14 left-8;
      }
      .lds-grid div:nth-child(9) {
        animation-delay: -1.6s;
        @apply top-14 left-14;
      }
      @keyframes lds-grid {
        0%,
        100% {
          opacity: 1;
        }
        50% {
          opacity: 0.5;
        }
      }
      .preloading-container {
        @apply fixed left-0 top-0 right-0 bottom-0 bg-[#16143e] flex justify-center flex-col;
      }
      .preloading-text {
        font-family: 'Roboto', sans-serif;
        @apply text-center text-white pt-1 leading-7 mt-4 text-[1.125rem];
        @apply max-md:leading-6 text-[1rem];
      }
    
      .preloading-header {
        @apply font-bold leading-10 text-[1.5rem] hidden;
        @apply max-lg:mt-6;
        @apply max-md:leading-8 text-[1.125rem];
      }
}