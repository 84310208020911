@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .reward-item {
        border-bottom-color: rgba(38, 34, 98, 0.20);
        @apply flex items-start border-b border-solid;
    }

    .faq-accordion {
        @apply mb-[80px];
    }

    .faq-accordion .mantine-UnstyledButton-root:active {
        @apply bg-transparent;
    }

    .faq-accordion .mantine-Accordion-label {
        @apply leading-[21px];
    }

    .faq-accordion .mantine-Accordion-item {
        border-bottom-color: rgba(38, 34, 98, 0.20);
        @apply border-b border-solid;
    }

    .faq-accordion .mantine-Accordion-item:focus-visible {
        @apply outline-none;
    }
}